const JCB = () => {
    return <svg
        height="40"
        width="40"
        xmlSpace="preserve"
        viewBox="0 0 300.00001 231.511"
        y="0px"
        x="0px"
        id="レイヤー_1"
        version="1.1"
    >
        <defs id="defs6422" />
        <style>
            {`
          .st0 { fill: #FFFFFF; }
          .st1 { fill: url(#SVGID_1_); }
          .st2 { fill: url(#SVGID_2_); }
          .st3 { fill: url(#SVGID_3_); }
          .st4 { fill: url(#SVGID_4_); }
          .st5 { fill: url(#SVGID_5_); }
        `}
        </style>
        <g transform="translate(-9.4000001,96.810998)" id="g6321">
            <g
                transform="matrix(1.8215159,0,0,1.8215159,-8.5437653,-109.83667)"
                id="g6323"
            >
                <path
                    style={{ fill: '#ffffff' }}
                    id="path6325"
                    d="m 174,108.3 c 0,14 -11.4,25.4 -25.4,25.4 l -138.2,0 0,-100.6 c 0,-14 11.4,-25.4 25.4,-25.4 l 138.2,0 0,100.6 z"
                    className="st0"
                />
                <g id="g6327">
                    <linearGradient
                        gradientTransform="matrix(1.125,0,0,1.125,-11.9755,-13.8615)"
                        y2="81.398598"
                        x2="157.3299"
                        y1="81.398598"
                        x1="117.3856"
                        gradientUnits="userSpaceOnUse"
                        id="SVGID_1_"
                    >
                        <stop
                            id="stop6330"
                            style={{ stopColor: '#007940' }}
                            offset="0"
                        />
                        <stop
                            id="stop6332"
                            style={{ stopColor: '#00873F' }}
                            offset="0.2285"
                        />
                        <stop
                            id="stop6334"
                            style={{ stopColor: '#40A737' }}
                            offset="0.7433"
                        />
                        <stop
                            id="stop6336"
                            style={{ stopColor: '#5CB531' }}
                            offset="1"
                        />
                    </linearGradient>
                    <path
                        style={{ fill: 'url(#SVGID_1_)' }}
                        id="path6338"
                        d="m 129,82.5 10.5,0 c 0.3,0 1,-0.1 1.3,-0.1 2,-0.4 3.7,-2.2 3.7,-4.7 0,-2.4 -1.7,-4.2 -3.7,-4.7 -0.3,-0.1 -0.9,-0.1 -1.3,-0.1 l -10.5,0 0,9.6 z"
                        className="st1"
                    />
                    <linearGradient
                        gradientTransform="matrix(1.125,0,0,1.125,-11.9755,-13.8615)"
                        y2="75.171402"
                        x2="157.3318"
                        y1="75.171402"
                        x1="117.3844"
                        gradientUnits="userSpaceOnUse"
                        id="SVGID_2_"
                    >
                        <stop
                            id="stop6341"
                            style={{ stopColor: '#007940' }}
                            offset="0"
                        />
                        <stop
                            id="stop6343"
                            style={{ stopColor: '#00873F' }}
                            offset="0.2285"
                        />
                        <stop
                            id="stop6345"
                            style={{ stopColor: '#40A737' }}
                            offset="0.7433"
                        />
                        <stop
                            id="stop6347"
                            style={{ stopColor: '#5CB531' }}
                            offset="1"
                        />
                    </linearGradient>
                    <path
                        style={{ fill: 'url(#SVGID_2_)' }}
                        id="path6349"
                        d="m 138.3,16.2 c -10,0 -18.2,8.1 -18.2,18.2 l 0,18.9 25.7,0 c 0.6,0 1.3,0 1.8,0.1 5.8,0.3 10.1,3.3 10.1,8.5 0,4.1 -2.9,7.6 -8.3,8.3 l 0,0.2 c 5.9,0.4 10.4,3.7 10.4,8.8 0,5.5 -5,9.1 -11.6,9.1 l -28.2,0 0,37 26.7,0 c 10,0 18.2,-8.1 18.2,-18.2 l 0,-90.9 -26.6,0 z"
                        className="st2"
                    />
                    <linearGradient
                        gradientTransform="matrix(1.125,0,0,1.125,-11.9755,-13.8615)"
                        y2="68.399101"
                        x2="157.33051"
                        y1="68.399101"
                        x1="117.3846"
                        gradientUnits="userSpaceOnUse"
                        id="SVGID_3_"
                    >
                        <stop
                            id="stop6352"
                            style={{ stopColor: '#007940' }}
                            offset="0"
                        />
                        <stop
                            id="stop6354"
                            style={{ stopColor: '#00873F' }}
                            offset="0.2285"
                        />
                        <stop
                            id="stop6356"
                            style={{ stopColor: '#40A737' }}
                            offset="0.7433"
                        />
                        <stop
                            id="stop6358"
                            style={{ stopColor: '#5CB531' }}
                            offset="1"
                        />
                    </linearGradient>
                    <path
                        style={{ fill: 'url(#SVGID_3_)' }}
                        id="path6360"
                        d="m 143.2,63.1 c 0,-2.4 -1.7,-4 -3.7,-4.3 -0.2,0 -0.7,-0.1 -1,-0.1 l -9.5,0 0,8.8 9.5,0 c 0.3,0 0.9,0 1,-0.1 2,-0.3 3.7,-1.9 3.7,-4.3 z"
                        className="st3"
                    />
                </g>
                <linearGradient
                    gradientTransform="matrix(1.125,0,0,1.125,-11.9755,-13.8615)"
                    y2="75.171402"
                    x2="68.522102"
                    y1="75.171402"
                    x1="27.9594"
                    gradientUnits="userSpaceOnUse"
                    id="SVGID_4_"
                >
                    <stop id="stop6363" style={{ stopColor: '#1F286F' }} offset="0" />
                    <stop
                        id="stop6365"
                        style={{ stopColor: '#004E94' }}
                        offset="0.4751"
                    />
                    <stop
                        id="stop6367"
                        style={{ stopColor: '#0066B1' }}
                        offset="0.8261"
                    />
                    <stop
                        id="stop6369"
                        style={{ stopColor: '#006FBC' }}
                        offset="1"
                    />
                </linearGradient>
                <path
                    style={{ fill: 'url(#SVGID_4_)' }}
                    id="path6371"
                    d="m 37.7,16.2 c -10,0 -18.2,8.1 -18.2,18.2 l 0,44.9 c 5.1,2.5 10.4,4.1 15.7,4.1 6.3,0 9.7,-3.8 9.7,-9 l 0,-21.2 15.6,0 0,21.1 c 0,8.2 -5.1,14.9 -22.4,14.9 -10.5,0 -18.7,-2.3 -18.7,-2.3 l 0,38.3 26.7,0 c 10,0 18.2,-8.1 18.2,-18.2 l 0,-90.8 -26.6,0 z"
                    className="st4"
                />
                <linearGradient
                    gradientTransform="matrix(1.125,0,0,1.125,-11.9755,-13.8615)"
                    y2="75.171402"
                    x2="111.8553"
                    y1="75.171402"
                    x1="72.459503"
                    gradientUnits="userSpaceOnUse"
                    id="SVGID_5_"
                >
                    <stop id="stop6374" style={{ stopColor: '#6C2C2F' }} offset="0" />
                    <stop
                        id="stop6376"
                        style={{ stopColor: '#882730' }}
                        offset="0.1735"
                    />
                    <stop
                        id="stop6378"
                        style={{ stopColor: '#BE1833' }}
                        offset="0.5731"
                    />
                    <stop
                        id="stop6380"
                        style={{ stopColor: '#DC0436' }}
                        offset="0.8585"
                    />
                    <stop id="stop6382" style={{ stopColor: '#E60039' }} offset="1" />
                </linearGradient>
                <path
                    style={{ fill: 'url(#SVGID_5_)' }}
                    id="path6384"
                    d="m 88,16.2 c -10,0 -18.2,8.1 -18.2,18.2 l 0,23.8 c 4.6,-3.9 12.6,-6.4 25.5,-5.8 6.9,0.3 14.3,2.2 14.3,2.2 l 0,7.7 c -3.7,-1.9 -8.1,-3.6 -13.8,-4 -9.8,-0.7 -15.7,4.1 -15.7,12.5 0,8.5 5.9,13.3 15.7,12.5 5.7,-0.4 10.1,-2.2 13.8,-4 l 0,7.7 c 0,0 -7.3,1.9 -14.3,2.2 -12.9,0.6 -20.9,-1.9 -25.5,-5.8 l 0,42 26.7,0 c 10,0 18.2,-8.1 18.2,-18.2 l 0,-91 -26.7,0 z"
                    className="st5"
                />
            </g>
        </g>
    </svg>
};

export default JCB;