const UnionPay = () => {
    return <svg width="256px" height="160px" viewBox="0 0 256 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid">
        <g>
            <path d="M50.041631,0.000667563305 L113.820211,0.000667563305 C122.723127,0.000667563305 128.260438,7.25731345 126.183558,16.1897657 L96.4900715,143.675416 C94.3945365,152.576777 85.480739,159.839641 76.571605,159.839641 L12.7992433,159.839641 C3.908764,159.839641 -1.64098444,152.576777 0.435896208,143.675416 L30.1418186,16.1897657 C32.2186993,7.25731345 41.1278333,0.000667563305 50.041631,0.000667563305" fill="#E21836"></path>
            <path d="M108.513283,0.000667563305 L181.857018,0.000667563305 C190.758378,0.000667563305 186.744527,7.25731345 184.650546,16.1897657 L154.961724,143.675416 C152.878625,152.576777 153.528427,159.839641 144.608411,159.839641 L71.2646775,159.839641 C62.3446615,159.839641 56.8244495,152.576777 58.919985,143.675416 L88.6072525,16.1897657 C90.715224,7.25731345 99.6057035,0.000667563305 108.513283,0.000667563305" fill="#00447C"></path>
            <path d="M178.94783,0.000667563305 L242.726409,0.000667563305 C251.641762,0.000667563305 257.179074,7.25731345 255.085093,16.1897657 L225.396271,143.675416 C223.300736,152.576777 214.380719,159.839641 205.466922,159.839641 L141.717879,159.839641 C132.797863,159.839641 127.265215,152.576777 129.354532,143.675416 L159.048017,16.1897657 C161.124899,7.25731345 170.027814,0.000667563305 178.94783,0.000667563305" fill="#007B84"></path>
            <path d="M66.698338,40.8561434 C60.139686,40.9229891 58.202715,40.8561434 57.5840035,40.7100156 C57.346157,41.8401744 52.920349,62.256098 52.9079125,62.273198 C51.9549725,66.403641 51.261643,69.347961 48.9064975,71.2491775 C47.5695833,72.3544635 46.0088137,72.8876745 44.1993159,72.8876745 C41.2907503,72.8876745 39.5962892,71.4434965 39.3118063,68.704377 L39.2573971,67.7638735 C39.2573971,67.7638735 40.1434913,62.231225 40.1434913,62.200134 C40.1434913,62.200134 44.7884909,43.5952629 45.6201759,41.1359627 C45.6637033,40.9960531 45.6761397,40.9229891 45.6870215,40.8561434 C36.6457507,40.9354255 35.0430082,40.8561434 34.9326351,40.7100156 C34.8720075,40.9105527 34.6481522,42.0640298 34.6481522,42.0640298 L29.9052159,63.0333735 L29.4979234,64.8117805 L28.7097659,70.6289115 C28.7097659,72.3544635 29.0486581,73.762887 29.7233334,74.9536735 C31.8841598,78.729679 38.0479561,79.2955355 41.5348148,79.2955355 C46.0274683,79.2955355 50.241857,78.341041 53.089795,76.598389 C58.0332685,73.677387 59.3266555,69.1116695 60.4801325,65.0542905 L61.014898,62.972746 C61.014898,62.972746 65.7998075,43.6496723 66.6128375,41.1359627 C66.643929,40.9960531 66.656365,40.9229891 66.698338,40.8561434 Z" fill="#E21836"></path>
            <path d="M82.9786885,56.444252 C81.825211,56.444252 79.7172395,56.724071 77.823796,57.6521385 C77.1366845,58.0050215 76.486882,58.412314 75.8013245,58.818052 L76.420036,56.5841615 L76.081144,56.20796 C72.0657375,57.0209905 71.167207,57.129809 67.4580475,57.6521385 L67.147137,57.8588935 C66.7165265,61.4296985 66.3341065,64.1144085 64.7375825,71.133208 C64.129753,73.7199815 63.498605,76.331628 62.8659025,78.912183 L63.036903,79.2401935 C66.8377815,79.0396565 67.9912585,79.0396565 71.29468,79.0940655 L71.562063,78.8033645 C71.981792,76.65342 72.036201,76.1497455 72.965823,71.795447 C73.402652,69.7310025 74.313619,65.1948215 74.7628845,63.579643 C75.588351,63.197223 76.402936,62.8210215 77.1802115,62.8210215 C79.0316825,62.8210215 78.8062725,64.4362005 78.734763,65.079785 C78.655481,66.160198 77.9808055,69.68903 77.2890305,72.7188505 L76.8273285,74.674476 C76.5055365,76.1186545 76.152653,77.522414 75.830861,78.954156 L75.9707705,79.2401935 C79.7172395,79.0396565 80.859835,79.0396565 84.0591015,79.0940655 L84.435303,78.8033645 C85.013596,75.4455335 85.183042,74.547003 86.209046,69.6579385 L86.725157,67.411612 C87.727843,63.0153405 88.2315175,60.786114 87.4728965,58.970398 C86.670748,56.93549 84.7462135,56.444252 82.9786885,56.444252 Z" fill="#00447C"></path>
            <path d="M101.165696,50.891141 C100.904105,50.8315637 100.686585,50.6628556 100.685686,50.4110637 C100.685686,50.4076319 100.751175,50.2657817 100.833496,50.266865 C101.016258,50.267409 101.24355,50.4715986 101.24355,50.7040158 C101.24355,50.9376462 101.016258,51.1417521 100.833496,51.1426142 C100.751175,51.1436481 100.687258,51.0018441 100.685686,50.7992288 C100.684841,50.552202 100.81522,50.377899 101.165696,50.3914644 Z" fill="#007B84"></path>
        </g>
    </svg>
};

export default UnionPay;
