import { createSlice } from "@reduxjs/toolkit";

// Utility Service
import { getToken, getUser, getTheme } from '../utils/authutils';

const initialState = {
    token: getToken(),
    user: getUser(),
    theme: getTheme() || "dark",
    signIn: false,
    signInModal: false,
    disclaimerModal: false,
    disclaimerUrl: '',
    recoveryCodeMode: false,
    recoveryCode: null,
    recoveryCodeModal: false,
    reactivateWelcomeModal: false,
    recoveryAlertModal: false,
    invoicePdfLoading: false
};

export const userSlice = createSlice({
    name: "User",
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setTheme: (state, action) => {
            state.theme = action.payload;
        },
        setSignIn: (state, action) => {
            state.signIn = action.payload;
        },
        setSignInModal: (state, action) => {
            state.signInModal = action.payload;
        },
        setDisclaimerModal: (state, action) => {
            state.disclaimerModal = action.payload;
        },
        setDisclaimerUrl: (state, action) => {
            state.disclaimerUrl = action.payload;
        },
        setRecoveryCodeMode: (state, action) => {
            state.recoveryCodeMode = action.payload;
        },
        setRecoveryCode: (state, action) => {
            state.recoveryCode = action.payload;
        },
        setRecoveryCodeModal: (state, action) => {
            state.recoveryCodeModal = action.payload;
        },
        setReactivateWelcomeModal: (state, action) => {
            state.reactivateWelcomeModal = action.payload;
        },
        setRecoveryAlertModal: (state, action) => {
            state.recoveryAlertModal = action.payload;
        },
        setInvoicePdfLoading: (state, action) => {
            state.invoicePdfLoading = action.payload;
        }
    }
});

export const {
    setToken,
    setUser,
    setTheme,
    setSignIn,
    setSignInModal,
    setDisclaimerModal,
    setDisclaimerUrl,
    setRecoveryCodeMode,
    setRecoveryCode,
    setRecoveryCodeModal,
    setReactivateWelcomeModal,
    setRecoveryAlertModal,
    setInvoicePdfLoading
} = userSlice.actions;

export const selectToken = (state) => state.user.token;
export const selectUser = (state) => state.user.user;
export const selectTheme = (state) => state.user.theme;
export const selectSignIn = (state) => state.user.signIn;
export const selectSignInModal = (state) => state.user.signInModal;
export const selectDisclaimerModal = (state) => state.user.disclaimerModal;
export const selectDisclaimerUrl = (state) => state.user.disclaimerUrl;
export const selectRecoveryCodeMode = (state) => state.user.recoveryCodeMode;
export const selectRecoveryCode = (state) => state.user.recoveryCode;
export const selectRecoveryCodeModal = (state) => state.user.recoveryCodeModal;
export const selectReactivateWelcomeModal = (state) => state.user.reactivateWelcomeModal;
export const selectRecoveryAlertModal = (state) => state.user.recoveryAlertModal;
export const selectInvoicePdfLoading = (state) => state.user.invoicePdfLoading;

export default userSlice.reducer;