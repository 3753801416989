const DinersClub = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="137" height="37" viewBox="0 0 137 37">
        <defs>
            <path id="fxd4ddphza" d="M0.028 0.286L2.227 0.286 2.227 2.47 0.028 2.47z" />
            <path id="7spq7apxic" d="M0 0.363L42.216 0.363 42.216 36.63 0 36.63z" />
            <path id="upd965j6re" d="M0 0.363L37.376 0.363 37.376 36.63 0 36.63z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <path fill="#231F20" d="M46.28 30.459h.147c.374 0 .773-.05.773-.59v-5.437c0-.542-.399-.59-.773-.59h-.146v-.317c.408 0 1.033.043 1.546.043.522 0 1.148-.043 1.638-.043v.316h-.146c-.375 0-.773.05-.773.59v5.437c0 .542.398.591.773.591h.146v.313c-.5 0-1.128-.04-1.65-.04-.512 0-1.126.04-1.534.04v-.313zM56.64 28.727l.02-.02v-3.892c0-.851-.595-.974-.91-.974h-.228v-.315c.49 0 .972.042 1.462.042.427 0 .857-.042 1.283-.042v.315h-.154c-.44 0-.932.08-.932 1.316v4.723c0 .363.012.725.064 1.047h-.397l-5.378-5.956v4.274c0 .906.176 1.214.98 1.214h.178v.313c-.448 0-.898-.04-1.348-.04-.467 0-.949.04-1.418.04v-.313h.146c.72 0 .94-.485.94-1.317v-4.37c0-.581-.482-.931-.95-.931h-.136v-.315c.395 0 .803.042 1.199.042.315 0 .619-.042.93-.042l4.649 5.201zM59.831 24.046c-.784 0-.812.187-.97.944h-.313c.043-.29.093-.58.126-.883.04-.29.063-.581.063-.88h.25c.085.311.345.299.627.299h5.389c.28 0 .543-.01.564-.32l.248.042c-.038.278-.08.56-.113.84-.021.28-.021.56-.021.84l-.312.117c-.021-.386-.075-1-.774-1h-1.712v5.523c0 .801.366.891.867.891h.197v.313c-.406 0-1.138-.041-1.702-.041-.625 0-1.358.041-1.765.041v-.313h.198c.576 0 .866-.05.866-.869v-5.544h-1.713z" />
                <g transform="translate(-40.000000, -16.000000) translate(40.000000, 16.000000) translate(134.766304, 22.947511)">
                    <mask id="3hnrv5vwyb" fill="#fff">
                        <use xlinkHref="#fxd4ddphza" />
                    </mask>
                    <path fill="#231F20" d="M.953 1.381h.13c.141 0 .207-.106.207-.278 0-.175-.105-.235-.216-.235h-.12v.513zm-.371.577v-.054c.133-.019.16-.013.16-.099V.998C.741.883.728.844.584.85V.794h.565c.19 0 .37.093.37.29 0 .165-.108.282-.26.33l.179.252c.086.11.181.22.244.257v.035H1.47c-.103 0-.192-.216-.395-.503h-.12v.362c0 .074.025.068.158.087v.054h-.53zm.546.31c.492 0 .87-.416.87-.887 0-.47-.37-.892-.87-.892-.496 0-.872.421-.872.892 0 .471.376.886.872.886zm0-1.982c.629 0 1.1.482 1.1 1.095 0 .614-.471 1.09-1.1 1.09-.628 0-1.1-.476-1.1-1.09C.028.768.5.286 1.128.286z" mask="url(#3hnrv5vwyb)" />
                </g>
                <path fill="#231F20" d="M112.243 9.508h-.48c-.363-2.23-1.957-3.127-4.104-3.127-2.21 0-5.416 1.468-5.416 6.043 0 3.856 2.77 6.622 5.722 6.622 1.9 0 3.474-1.296 3.858-3.3l.44.113-.44 2.786c-.806.498-2.977 1.01-4.244 1.01-4.49 0-7.328-2.879-7.328-7.17 0-3.91 3.51-6.715 7.273-6.715 1.555 0 3.05.496 4.528 1.01l.191 2.728zM112.934 18.873h.366c.538 0 .923 0 .923-.632V7.623c0-1.242-.288-1.28-1.018-1.49v-.305c.766-.249 1.573-.59 1.977-.82.21-.115.365-.212.421-.212.117 0 .154.115.154.27V18.24c0 .632.423.632.96.632h.326v.497c-.65 0-1.323-.037-2.016-.037-.689 0-1.38.02-2.093.037v-.497z" />
            </g>
        </g>
    </svg>
}

export default DinersClub;